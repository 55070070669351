const state = () => ({
	bridgeName: null,
	locale: 'en',
	isOnline: false,
	partner: {
		id: 1,
		name: 'POSBytz',
		slug: 'posbytz',
		logo_square: '',
		logo_dark: '',
		domain: 'https://posbytz.com',
		beta_testing: { complete: [] }
	},
	syncDetails: {
		'merchant-details': {
			name: 'merchant details',
			type: 'down',
			lastSynced: null
		},
		devices: {
			name: 'device',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		floors: {
			name: 'floor',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'floor-tables': {
			name: 'table',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		employees: {
			name: 'employee',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'merchant-customer-groups': {
			name: 'customer groups',
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'merchant-customers': {
			name: 'customer',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		categories: {
			name: 'category',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'item-brands': {
			name: 'brand',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'merchant-price-categories': {
			name: 'price category',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		taxes: {
			name: 'tax',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		discounts: {
			name: 'discount',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'merchant-charges': {
			name: 'charge',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		items: {
			name: 'item',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'item-variation-groups': {
			name: 'item variation groups',
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'merchant-payment-methods': {
			name: 'payment method',
			pluralizationIndex: 2,
			type: 'down',
			total: 0,
			lastSynced: null
		},
		'employee-shifts': {
			name: 'employee shift',
			pluralizationIndex: 2,
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		'cash-drawer-shifts': {
			name: 'cash drawer shift',
			pluralizationIndex: 2,
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		order: {
			name: 'order',
			pluralizationIndex: 2,
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		'cash-drawer-shift-event': {
			name: 'cash ins & outs',
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		'order-refund': {
			name: 'order refund',
			pluralizationIndex: 2,
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		},
		'customer-credit': {
			name: 'customer credits',
			type: 'up',
			total: 0,
			synced: 0,
			lastSynced: null
		}
	},
	primaryDevice: null,
	nextBussinessOpeningTime: null,
	appVersion: process.env.VERSION,
	uuid: null,
	device: null,
	deviceId: null,
	locationId: null, // TODO: remove in next release (use location object instead)
	locationName: null, // TODO: remove in next release (use location object instead)
	isLoggedIn: false,
	loginToken: null,
	merchant: {
		features: {}
	},
	selectedMerchant: null,
	location: {},
	settings: {
		sale: {},
		general: {},
		barcode: {},
		receipt: {},
		kot_receipt: {},
		denominations: []
	},
	localSettings: {
		defaultCustomerId: null
	},
	employees: [],
	employee: null,
	employeeShift: null,
	progress: {
		show: false,
		title: '',
		name: '',
		value: 0
	},
	loading: {
		show: false,
		text: '',
		status: ''
	},
	shareReceipt: null,
	passcode: {
		show: false,
		value: '',
		type: '',
		force: false
	},
	cashDrawer: {
		show: false,
		close: true,
		type: ''
	},
	cashDrawerShift: null,
	selectedItem: {
		show: false
	},
	item: {
		show: false
	},
	favorites: [],
	charges: [],
	tip: null,
	cart: {
		orderType: null,
		vehicleNumber: null,
		priceCategory: null,
		additionalInfo: {},
		ignoredCharges: [],
		selectedDiscount: null,
		table: null,
		waiter: null,
		customer: null,
		numberOfGuest: null,
		tableStartTime: null,
		advanceOrder: {
			enabled: false,
			scheduledOn: {
				startDate: null,
				endDate: null
			},
			orderType: 'pickup',
			deliveryAddress: {
				line: '',
				area: '',
				city: '',
				state: '',
				zip: '',
				landmark: '',
				coordinates: []
			},
			isDeliveryAddressValid: false
		},
		charges: [],
		items: [],
		price: {
			subtotal: 0,
			tax: 0,
			taxes: [],
			discount: 0,
			discountedAmount: 0,
			discountedTax: 0,
			charge: 0,
			charges: [],
			tip: 0,
			roundOff: 0,
			total: 0
		}
	},
	onHold: {},
	checkout: {
		show: false
	},
	selectedTables: [],
	categoryView: 'button',
	paginationSize: 20,
	showOrderConfirmation: true,
	showItemSearchDropdown: false,
	showConfirmWOPrint: true,
	loopNotificationSound: true,
	kitchenDisplayZoomLevel: 1,
	archivalFrequency: 7,
	printerSettings: {
		paperSize: 3,
		bufferSize: 800,
		scaleFactor: 2,
		reprintKot: false,
		useDefaultKotPrinter: true,
		paperFeedLength: 1,
		cutPaperAfterPrint: true,
		qrPrintMode: 1
	},
	kots: [],
	kotCancellationReason: [],
	kotCancellationModal: {
		show: false,
		data: {}
	},
	baudRates: [115200, 57600, 38400, 19200, 9600, 4800, 2400, 1800, 1200, 600, 300, 200, 150, 134, 110, 75, 50],
	scanner: {
		hardware: 'others',
		serialPortOptions: {}
	},
	weighingScale: {
		enabled: false,
		hardware: 'others',
		serialPortOptions: {
			path: null,
			baudRate: 9600,
			parity: 'none',
			dataBits: 8,
			stopBits: 1,
			command: ''
		}
	},
	selectedTable: null,
	selectedOrder: null,
	keyboardShortcuts: {
		show: true,
		search_focus: 'S',
		default_price_category: 'D',
		quantity_delimiter: '*',
		checkout: 'C',
		add_customer: 'A',
		cash_payment: 'Q',
		card_payment: 'W',
		credit_payment: 'E',
		split_payment: 'R',
		other_payment: 'T',
		order_confirm: 'P',
		order_confirm_wo_print: 'O',
		cash_order_confirm: 'V',
		complete_table_order: 'L'
	},
	lastSelectedTableId: null,
	denominations: [],
	isWaiterAppRunning: false,
	discounts: [],
	customerOtpModal: null,
	showOrderDiscounts: false,
	newOnlineOrdersCount: 0,
	advanceOrdersCount: 0,
	otpModalType: null,
	internationalBancard: {
		enabled: false,
		terminalId: null,
		privateToken: null
	},
	receiptPreview: null,
	tidyPay: {
		enabled: false,
		userName: null,
		password: null,
		accountId: null,
		terminalId: null,
		showModal: false,
		status: 'INITIALIZING',
		code: null
	},
	skyband: {
		type: null,
		enabled: false,
		ipAddress: null,
		portNumber: null,
		cashRegisterNumber: null,
		ecrNumber: null,
		bluetoothDevice: null
	},
	pineLabs: {
		enabled: false,
		IMEI: null,
		merchantId: null,
		securityToken: null,
		merchantStorePoscode: null
	},
	clover: {
		enabled: false,
		serialNumber: null,
		secret: null,
		showModal: false,
		status: 'INITIALIZING',
		cancel: false
	},
	selectedSecondaryDisplay: null,
	foreignCurrencyExchangeRate: {
		exchangeRateType: 'fixed',
		exchangeRateValue: 0,
		cache: null
	},
	taxCreationModal: {
		show: null,
		data: {}
	}
})

export default state
