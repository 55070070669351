import { localize } from 'vee-validate'

const mutations = {
	setLocale (state, locale) {
		state.locale = locale
		this.$i18n.locale = locale
		localize(locale)
		this.$bridge.setLocalStorage('locale', locale)
	},
	setState (state, data) {
		state[data.key] = data.value

		if (data.save === true) {
			this.$bridge.setLocalStorage(
				data.key,
				typeof data.value === 'object' ? this.app.objToJson(data.value) : data.value
			)
		} else if (data.save === false) {
			this.$bridge.removeLocalStorage(data.key)
		}
	},
	updateSyncDetails (state, data) {
		if (state.syncDetails[data.key]) {
			state.syncDetails = {
				...state.syncDetails,
				[data.key]: {
					...state.syncDetails[data.key],
					...data.value
				}
			}
			this.$bridge.setLocalStorage('syncDetails', this.app.objToJson(state.syncDetails))
		}
	},
	setSettings (state, settings) {
		state.settings = Object.assign({}, state.settings, settings)
		this.$bridge.setLocalStorage('settings', this.app.objToJson(state.settings))
	},
	setLocalSettings (state, settings) {
		state.localSettings = Object.assign({}, state.localSettings, settings)
		this.$bridge.setLocalStorage('localSettings', this.app.objToJson(state.localSettings))
	},
	setProgress (state, data) {
		if ('show' in data) {
			state.progress.show = data.show
		}

		if ('title' in data) {
			state.progress.title = data.title
		}

		if ('name' in data) {
			state.progress.name = data.name
		}

		if ('value' in data) {
			state.progress.value = data.value
		}
	},
	resetProgress (state) {
		state.progress = {
			show: false,
			title: '',
			name: '',
			value: 0
		}
	},
	setLoading (state, data) {
		if ('show' in data) {
			state.loading.show = data.show
		}

		if ('text' in data) {
			state.loading.text = data.text
		}

		if ('status' in data) {
			state.loading.status = data.status
		}
	},
	resetLoading (state) {
		state.loading = {
			show: false,
			text: '',
			status: ''
		}
	},
	setPasscode (state, data) {
		state.passcode = { ...state.passcode, ...data }
	},
	resetPasscode (state) {
		state.passcode = {
			show: false,
			value: '',
			type: ''
		}
	},
	resetCashDrawer (state) {
		state.cashDrawer = {
			show: false,
			close: true,
			type: ''
		}
	},
	setCart (state, cart) {
		state.cart = { ...state.cart, ...cart }
	},
	resetCart (state) {
		state.cart = {
			orderType: null,
			vehicleNumber: null,
			priceCategory: state.cart.priceCategory,
			additionalInfo: {},
			table: null,
			waiter: null,
			customer: null,
			advanceOrder: {
				enabled: false,
				scheduledOn: {
					startDate: null,
					endDate: null
				},
				orderType: 'pickup',
				deliveryAddress: {
					line: '',
					area: '',
					city: '',
					state: '',
					zip: '',
					landmark: '',
					coordinates: []
				},
				isDeliveryAddressValid: false
			},
			ignoredCharges: [],
			selectedDiscount: null,
			charges: [],
			items: [],
			price: {
				subtotal: 0,
				tax: 0,
				taxes: [],
				discount: 0,
				discountedAmount: 0,
				discountedTax: 0,
				charge: 0,
				charges: [],
				tip: 0,
				roundOff: 0,
				total: 0
			}
		}
	},
	resetCheckout (state) {
		state.checkout = {
			show: false
		}
	},
	setPrinterSettings (state, data) {
		state.printerSettings = Object.assign({}, state.printerSettings, data)
		this.$bridge.setLocalStorage('printerSettings', this.app.objToJson(data))
	}
}

export default mutations
